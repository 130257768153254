<template>
  <div>
    <Row class="p-b-10">
      <i-col span="12">
        <span class="workplatform-title">{{ contractInfo.name }}</span>
        <span class="m-l-10 workplatform-title">{{ contractInfo.code }}</span>
        <Tag class="m-l-5" color="blue">{{ contractInfo.rentTypeName }}</Tag>
        <!-- <Tag class="m-l-5" color="green" v-if="!showDisCount">
          {{contract.contractCategoryName}}
        </Tag>
        <Tag
          class="m-l-5"
          color="blue"
          v-else-if="contract.contractCategory === 2"
          >{{contract.contractCategoryName}}</Tag
        >
        <Tag class="m-l-5" color="blue" v-else-if="contract.type === 3"
          >代理商合同</Tag
        > -->
      </i-col>
      <i-col span="12" class="text-right">
        <Row>
          <i-col span="24"
            ><label class="title">创建时间 </label
            ><label>{{ contractInfo.createTime }}</label></i-col
          >

          <i-col span="24" v-if="contractInfo.lastApprovedTime"
            ><label class="title">最后审核时间 </label
            ><label>{{ contractInfo.lastApprovedTime }}</label></i-col
          >
        </Row>
      </i-col>
    </Row>
    <Row class="p-b-10"  v-if="contractInfo.firstParty">
      <i-col span="24">
        <span class="title"> 甲方 </span>
        <span>{{ contractInfo.firstParty.partyName || "无" }}</span>
      </i-col>
      <i-col span="8"
        ><span class="title">法定代表人 </span
        >{{ contractInfo.firstParty.partyLegalName }}</i-col
      >
      <i-col span="8"
        ><span class="title"
          >{{
            contractInfo.firstParty.partyType === 10
              ? "社会统一信用代码"
              : "身份证号"
          }} </span
        >{{ contractInfo.firstParty.partyOrgCode }}</i-col
      >
      <i-col span="8"
        ><span class="title">地址 </span
        >{{ contractInfo.firstParty.partyAddress }}</i-col
      >
    </Row>
    <Row class="p-b-10"  v-if="contractInfo.secondParty">
      <i-col span="24"  >
        <span class="title">乙方 </span>
        <span>{{ contractInfo.secondParty.partyName }}</span>
      </i-col>
      <i-col span="8"
        ><span class="title">法定代表人 </span
        >{{ contractInfo.secondParty.partyLegalName }}</i-col
      >
      <i-col span="8"
        ><span class="title"
          >{{
            contractInfo.secondParty.partyType === 10
              ? "社会统一信用代码"
              : "身份证号"
          }} </span
        >{{ contractInfo.secondParty.partyOrgCode }}</i-col
      >
      <i-col span="8"
        ><span class="title">地址 </span
        >{{ contractInfo.secondParty.partyAddress }}</i-col
      >
    </Row>

    <Tabs value="info" class="text-white">
      <TabPane label="基础信息" name="info">
        <div style="font-size: 14px">合同日期</div>
        <Row class="p-b-5 p-t-10" style="line-height: 24px">
          <i-col span="6"
            ><span class="title">合同签订日期 </span
            >{{ contractInfo.signDate }}</i-col
          >
          <i-col span="6"
            ><span class="title">合同开始日期 </span
            >{{ contractInfo.startDate }}</i-col
          >
          <i-col span="6"
            ><span class="title">合同结束日期 </span
            >{{ contractInfo.endDate }}</i-col
          >
          <!-- <i-col span="6"
            ><span class="title">房屋交付日期 </span
            >{{ contractInfo.deliveryDate }}</i-col
          > -->
          <i-col span="6"
            ><span class="title">最晚运营日期 </span
            >{{ contractInfo.businessDate }}</i-col
          >
        </Row>

        <div style="font-size: 14px">资产信息</div>
        <Row class="p-b-5 p-t-10" style="line-height: 24px">
          <i-col span="24">
            <Table
              size="small"
              :columns="columnData"
              :data="tableData"
              stripe
            ></Table
          ></i-col>
        </Row>
        <Row>
          <i-col span="24">
            <div class="mb-2">
              <div style="font-size: 14px">免租期</div>
            </div>
            <Row :gutter="8" class="p-b-5 p-t-10" style="line-height: 24px">
              <i-col span="24">
                <div v-if="!freePeriodArray.length">本合同未约定免租期</div>
                <div v-else>本合同约定免租期如下：</div>
                <p v-for="(item, index) in freePeriodArray" :key="index">
                  {{ index + 1 }}.
                  <label v-if="item.type === 1"
                    >{{ item.startDate }} 至 {{ item.endDate }}
                  </label>
                  <label v-if="item.type === 2"
                    >自资产交付日起{{ item.duration }}个月</label
                  >
                </p>
              </i-col>
            </Row>
          </i-col>
          <i-col span="24">
            <div class="mb-2">
              <div style="font-size: 14px">其他费用&结算方式</div>
            </div>
            <Row :gutter="8" class="p-b-5 p-t-10" style="line-height: 24px">
              <i-col span="24">
                <p>
                  1.本合同⽣效之⽇，⼄⽅向甲⽅⽀付履约保证⾦{{
                    contractInfo.depositAmount
                  }}元（⼈⺠币{{
                    formatUpperMoney(contractInfo.depositAmount)
                  }}）。
                </p>
                <p>
                  2.乙方应同时按照每平方米{{
                    contractInfo.propertyAmount
                  }}
                  元/㎡·月（人民币{{
                    formatUpperMoney(contractInfo.propertyAmount)
                  }}）向甲方另行支付运营管理费。
                </p>
                <p>
                  3.⼄⽅每{{
                    contractInfo.paymentCycle
                  }}个⽉结算⼀次，⼄⽅应在每
                  {{ contractInfo.paymentCycle }} 个⽉期满
                  {{ contractInfo.paymentAdvanceDay }} ⽇前向甲方⽀付结算金额。
                </p>
                <p v-if="contractInfo.paymentStartDate">
                  4.首期租金（{{ contractInfo.paymentStartDate }}至{{
                    contractInfo.paymentEndDate
                  }}）⼄⽅应在本合同生效后
                  {{ contractInfo.paymentAdvanceDay }} ⽇内一次性支付甲方。
                </p>
              </i-col>
            </Row>
          </i-col>
      <i-col span="24">
        <div class="mb-2">
          <div style="font-size: 14px">合同附件</div>
        </div>
        <Row :gutter="8" class="mb-2">
          <i-col span="24">
            <RadioGroup v-model="fileType" type="button" button-style="solid">
              <Radio
                v-for="item in contractfiletypes"
                :key="item.id"
                :label="item.id"
                >{{ item.name }}</Radio
              >
            </RadioGroup>
          </i-col>
          <i-col span="24">
            <Table :columns="fileColumns"  stripe size="small" :data="fileList">
            </Table>
          </i-col>
        </Row>
      </i-col>
        </Row>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
// import { getPositions } from '@/api/product/resource'
// import { addDevice, updateDevice } from '@/api/product/deviceedit'
// import { ParseDate } from '@/utils/dateFormat'

import { downloadFileRequest } from '@/utils/download'
import { signurlByAttach } from '@/api/resource/endpoint'
import { parseUpperMoney } from '@/utils/index'
import { toMoney } from '@/utils/wnumb_own'
/** new api */
import { listDictLabel } from '@/api/contract/dict'
import {
  getContractInfo,
  listResourceAndFee,
  listFreePeriod
} from '@/api/invest/contract'

import {
  getFileList
} from '@/api/invest/file'
export default {
  components: {},
  props: {},
  data () {
    return {
      contractInfo: {},
      deviceListModal: false,

      contractResourceArray: [],
      tableData: [],
      columnData: [
        { title: '内部编码', dataIndex: 'innerCode', key: 'innerCode' },
        { title: '房屋所有权证编号', dataIndex: 'certNo', key: 'certNo' },
        {
          title: '类别',
          dataIndex: 'resourceTypeName',
          key: 'resourceTypeName'
        },
        // { title: '栋号-单元号-楼层', dataIndex: 'stationName', key: 'stationName' },
        { title: '产权面积（㎡）', dataIndex: 'area', key: 'area' },
        // { title: '计租面积（㎡）', dataIndex: 'signArea', key: 'signArea' },
        {
          title: '计费周期',
          width: '200px',
          key: 'feeStartDate',
          align: 'center',
          render: (h, params) => {
            const resultStr = []
            params.row.feeList.forEach((item) => {
              resultStr.push(h('p', {}, item.startDate + '至' + item.endDate))
            })

            return h('div', resultStr)
          }
        },
        {
          title: '签约面积（㎡）',
          key: 'feeSignArea',
          align: 'center',
          render: (h, params) => {
            const resultStr = []
            params.row.feeList.forEach((item) => {
              resultStr.push(h('p', {}, item.area))
            })

            return h('div', resultStr)
          }
        },
        {
          title: '计费单价（元/㎡·⽉）',
          key: 'feeSignPrice',
          align: 'center',
          render: (h, params) => {
            const resultStr = []
            params.row.feeList.forEach((item) => {
              resultStr.push(h('p', {}, item.signPrice))
            })

            return h('div', resultStr)
          }
        },
        {
          title: '月租金（元）',
          width: '160px',
          key: 'feeMonthPrice',
          align: 'center',
          render: (h, params) => {
            const resultStr = []
            params.row.feeList.forEach((item) => {
              resultStr.push(h('p', {}, item.monthPrice))
            })

            return h('div', resultStr)
          }
        },

        { title: '交付日期', dataIndex: 'deliveryDate', key: 'deliveryDate' }
      ],
      freePeriodArray: [],

      contractfiletypes: [],
      fileType: 0,
      fileList: [],
      fileColumns: [
        { title: '文件名称', dataIndex: 'fileName', key: 'fileName' },
        { title: '文件分类', dataIndex: 'fileTypeName', key: 'fileTypeName' },
        { title: '文件类型', dataIndex: 'mimeType', key: 'mimeType' },
        // { title: '文件大小', dataIndex: 'length', key: 'length' },
        { title: '上传时间', dataIndex: 'createTime', key: 'createTime' },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.handleDownload(params.row)
                  }
                }
              },
              '下载'
            )
            const returnBtns = []

            returnBtns.push(detailButton)
            return h('div', returnBtns)
          }
        }
      ]
    }
  },
  mounted () {},
  created () {
    if (this.id) {
      this.initContractDetail()
      this.initlistResourceAndFee()
    }
  },
  methods: {

    handleDownload (file) {
      signurlByAttach({ attachId: file.filePath }).then(res => {
        downloadFileRequest(res.data, { }, file.fileName)
      })
    },
    formatMoney (number) {
      return toMoney(number)
    },
    formatUpperMoney (money) {
      return parseUpperMoney(money)
    },
    initContractDetail () {
      const that = this
      getContractInfo({ contractId: that.id, hideFlag: 1 }).then((res) => {
        that.contractInfo = res
        that.initFreePeriodList()

        that.initFileTypes()
      })
    },

    initFreePeriodList () {
      listFreePeriod({ contractId: this.contractInfo.id }).then((res) => {
        this.freePeriodArray = res
      })
    },
    initlistResourceAndFee () {
      const that = this
      listResourceAndFee({ contractId: that.id }).then((res) => {
        that.tableData = res
      })
    },
    initFileTypes () {
      // 文件类型
      listDictLabel({ dictType: 'contract_file_type' }).then((res) => {
        this.contractfiletypes = [{
          id: 0, name: '全部文件'
        }]
        this.contractfiletypes = this.contractfiletypes.concat(res.map((item) => {
          return { id: item.id, name: item.name }
        }))
        // this.fileType = this.contractfiletypes[0].id
        this.reloadFileList()
      })
    },
    reloadFileList () {
      const query = {
        category: 2,
        keyId: this.contractInfo.id
      }
      if (this.fileType > 0) {
        query.fileType = this.fileType
      }
      getFileList(query).then((res) => {
        this.fileList = res
      })
    }

  },
  watch: {
    fileType () {
      this.reloadFileList()
    },
    id () {
      this.initContractDetail()
    },
    beginUpdate () {
      this.initContractDetail()
    }
  },
  computed: {
    id () {
      return this.$store.state.investmentContract.id
    },
    beginUpdate () {
      return this.$store.state.investmentContract.beginUpdate
    }
  }
}
</script>
